import { Button, Center, Container, Flex, Group, Loader, Space, Text } from '@mantine/core';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useAdmin } from '../hooks/use-admin';

export const Layout = ({ children }) => {
  const { loading, admin } = useAdmin();

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/login';
  };

  if (loading) {
    return (
      <Center h="100vh" w="100vw">
        <Loader />
      </Center>
    );
  }

  if (!admin) return <Navigate to="/login" />;

  return (
    <View>
      <Header>
        <Container size="lg" h="100%">
          <Flex h="100%" align="center" justify="space-between">
            <LogoLink to="/">
              <Group align="center">
                <Logo src="/logo.png" />
              </Group>
            </LogoLink>
            <Group align="center">
              <Text className="email-text" fz="sm">
                {admin.email}
              </Text>
              <Button onClick={handleLogout} size="xs">
                Выйти
              </Button>
            </Group>
          </Flex>
          <Navbar />
        </Container>
      </Header>
      <Space h="16px" />
      <Container size="lg">
        <Content>{children}</Content>
      </Container>
    </View>
  );
};

const Navbar = () => {
  const { pathname } = window.location;

  const navigate = useNavigate();

  return (
    <NavbarContent>
      <div className={`link${pathname === '/' ? ' active' : ''}`} onClick={() => navigate('/')}>
        <Text size="sm" c={pathname === '/' ? '#228be6' : undefined}>
          Профиль
        </Text>
      </div>
      <div className={`link${pathname === '/references' ? ' active' : ''}`} onClick={() => navigate('/references')}>
        <Text size="sm" c={pathname === '/references' ? '#228be6' : undefined}>
          Справочник
        </Text>
      </div>
      <div className={`link${pathname === '/cars' ? ' active' : ''}`} onClick={() => navigate('/cars')}>
        <Text size="sm" c={pathname === '/cars' ? '#228be6' : undefined}>
          Объявления
        </Text>
      </div>
      <div className={`link${pathname === '/users' ? ' active' : ''}`} onClick={() => navigate('/users')}>
        <Text size="sm" c={pathname === '/users' ? '#228be6' : undefined}>
          Пользователи
        </Text>
      </div>
      <div
        className={`link${pathname === '/client-brands' ? ' active' : ''}`}
        onClick={() => navigate('/client-brands')}>
        <Text size="sm" c={pathname === '/client-brands' ? '#228be6' : undefined}>
          Дилеры
        </Text>
      </div>
      <div className={`link${pathname === '/complains' ? ' active' : ''}`} onClick={() => navigate('/complains')}>
        <Text size="sm" c={pathname === '/complains' ? '#228be6' : undefined}>
          Жалобы
        </Text>
      </div>
      <div
        className={`link${pathname === '/push-notifications' ? ' active' : ''}`}
        onClick={() => navigate('/push-notifications')}>
        <Text size="sm" c={pathname === '/push-notifications' ? '#228be6' : undefined}>
          Push уведомления
        </Text>
      </div>
    </NavbarContent>
  );
};

const NavbarContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  margin-bottom: -1px;
  overflow-x: auto;

  .link {
    background-color: #fff;
    padding: 8px 16px;
    cursor: pointer;
    border-bottom: 1px solid #e9ecef;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    border-top: 1px solid #fff;
  }

  .active {
    background-color: rgb(248, 249, 250);
    border-bottom: 1px solid rgb(248, 249, 250);
    border-left: 1px solid #e9ecef;
    border-right: 1px solid #e9ecef;
    border-top: 1px solid #e9ecef;
  }
`;

const Content = styled.div`
  padding: 16px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #e9ecef;
`;

const Header = styled.div`
  padding-top: 16px;
  background-color: #fff;
  border-bottom: 1px solid #e9ecef;
`;

const View = styled.div`
  .email-text {
    @media only screen and (max-width: 500px) {
      display: none;
    }
  }
`;

const Logo = styled.img`
  height: 25px;
`;

const LogoLink = styled(Link)`
  text-decoration: none;
`;

export const withLayout = (component) => {
  const Page = component;
  return (
    <Layout>
      <Page />
    </Layout>
  );
};
