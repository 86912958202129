import { useQuery } from '@apollo/client';
import { Loader, Space, Table, Text } from '@mantine/core';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { ADMIN_USER } from '../../gqls/user';
import { NameValue } from '../../components/name-value';
import { getCarStatus } from '../cars';

export const UserPage = () => {
  const params = useParams();

  const { data, loading } = useQuery(ADMIN_USER, { variables: { where: { id: params.id } } });

  const user = data?.adminUser;

  const carsRows =
    user?.cars?.map((item) => {
      return (
        <Table.Tr key={item.id}>
          <Table.Td>
            <Link to={`/cars/${item.id}`}>{item.id.substring(0, 8)}...</Link>
          </Table.Td>
          <Table.Td>{item.brand}</Table.Td>
          <Table.Td>{item.model}</Table.Td>
          <Table.Td>{item.year}</Table.Td>
          <Table.Td>{item.price} руб.</Table.Td>
          <Table.Td>{item.city}</Table.Td>
          <Table.Td>{getCarStatus(item.status)}</Table.Td>
        </Table.Tr>
      );
    }) || [];

  return (
    <>
      <Text>Пользователь</Text>
      <Space h="12px" />
      {loading ? (
        <Loader size="sm" />
      ) : user ? (
        <>
          {user.avatar ? (
            <>
              <Img src={`https://storage.yandexcloud.net/givgar/images/${user.avatar}`} />
              <Space h="10px" />
            </>
          ) : null}
          <NameValue name="Номер телефона" value={user.phone} />
          <NameValue name="Имя" value={user.name} />
          <NameValue name="Электронная почта" value={user.email} />
          <NameValue name="Дата рождения" value={dayjs(user.birthday).format('DD.MM.YYYY')} />
          <NameValue name="Пол" value={user.sex === 'male' ? 'Мужской' : 'Женский'} />
          <NameValue name="Бонусы" value={user.bonus} />
          <Space h="36px" />
          <Text>Объявления</Text>
          <Space h="12px" />
          <TableView>
            <Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>ID</Table.Th>
                  <Table.Th>Бренд</Table.Th>
                  <Table.Th>Модель</Table.Th>
                  <Table.Th>Год</Table.Th>
                  <Table.Th>Цена</Table.Th>
                  <Table.Th>Город</Table.Th>
                  <Table.Th>Статус</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{carsRows}</Table.Tbody>
            </Table>
          </TableView>
        </>
      ) : null}
    </>
  );
};

const Img = styled.img`
  height: 120px;
  object-fit: contain;
`;

const TableView = styled.div`
  overflow-x: scroll;
  position: relative;

  table {
    min-width: 1070px;
    width: 100%;

    th {
      white-space: nowrap;
    }
  }
`;
