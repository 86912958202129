import { useQuery } from '@apollo/client';
import { Button, Group, Loader, Space, Table, Text } from '@mantine/core';
import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ADMIN_CLIENT_BRAND } from '../../gqls/clientBrand';
import { NameValue } from '../../components/name-value';
import { getCarStatus } from '../cars';

export const ClientBrandPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { data, loading } = useQuery(ADMIN_CLIENT_BRAND, { variables: { where: { id: params.id } } });

  const clientBrand = data?.adminClientBrand;

  const carsRows =
    clientBrand?.cars?.map((item) => {
      return (
        <Table.Tr key={item.id}>
          <Table.Td>
            <Link to={`/cars/${item.id}`}>{item.id.substring(0, 8)}...</Link>
          </Table.Td>
          <Table.Td>{item.brand}</Table.Td>
          <Table.Td>{item.model}</Table.Td>
          <Table.Td>{item.year}</Table.Td>
          <Table.Td>{item.price} руб.</Table.Td>
          <Table.Td>{item.city}</Table.Td>
          <Table.Td>{getCarStatus(item.status)}</Table.Td>
        </Table.Tr>
      );
    }) || [];

  return (
    <>
      <Text>Дилер</Text>
      <Space h="12px" />
      {loading ? (
        <Loader size="sm" />
      ) : clientBrand ? (
        <>
          <Img src={`https://storage.yandexcloud.net/givgar/images/${clientBrand.image}`} />
          <Space h="16px" />
          <NameValue name="Электронная почта" value={clientBrand.email} />
          <NameValue name="Название" value={clientBrand.name} />
          <NameValue name="Сайт" value={clientBrand.website} />
          <NameValue name="Номер сортировки" value={clientBrand.sortNumber} />
          <NameValue name="Описание" value={clientBrand.description} column />
          <Space h="16px" />
          <Group>
            <Button onClick={() => navigate(`/client-brands/${params.id}/update`)} variant="outline">
              Изменить
            </Button>
          </Group>
          <Space h="36px" />
          <Text>Объявления</Text>
          <Space h="12px" />
          <TableView>
            <Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>ID</Table.Th>
                  <Table.Th>Бренд</Table.Th>
                  <Table.Th>Модель</Table.Th>
                  <Table.Th>Год</Table.Th>
                  <Table.Th>Цена</Table.Th>
                  <Table.Th>Город</Table.Th>
                  <Table.Th>Статус</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{carsRows}</Table.Tbody>
            </Table>
          </TableView>
        </>
      ) : null}
    </>
  );
};

const Img = styled.img`
  height: 120px;
  object-fit: contain;
`;

const TableView = styled.div`
  overflow-x: scroll;
  position: relative;

  table {
    min-width: 1070px;
    width: 100%;

    th {
      white-space: nowrap;
    }
  }
`;
