import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Flex,
  Group,
  LoadingOverlay,
  Modal,
  Pagination,
  Select,
  Space,
  Table,
  Text,
  Textarea,
} from '@mantine/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { notifications } from '@mantine/notifications';

import { CREATE_PUSH_NOTIFICATIONS, PUSH_NOTIFICATIONS } from '../../gqls/pushNotification';

const TAKE = 30;

export const PushNotificationsPage = () => {
  const [activePage, setActivePage] = useState(1);

  const [sendPushVisible, setSendPushVisible] = useState(false);
  const [type, setType] = useState('');
  const [text, setText] = useState('');

  const { data, loading, refetch } = useQuery(PUSH_NOTIFICATIONS, {
    fetchPolicy: 'network-only',
    variables: { skip: (activePage - 1) * TAKE, take: TAKE },
  });

  const [sendPushNotification, { loading: sending }] = useMutation(CREATE_PUSH_NOTIFICATIONS, {
    onCompleted: () => {
      setSendPushVisible(false);
      notifications.show({ color: 'green', title: 'Успешно', message: 'Push уведомление отправлено' });
      refetch();
    },
    onError: (err) => {
      console.error(err);
      notifications.show({ color: 'red', title: 'Ошибка', message: 'Произошла ошибка, повторите еще раз' });
    },
  });

  const pushNotifications = data?.pushNotifications || [];
  const pushNotificationsCount = data?.pushNotificationsCount || 0;

  const rows = pushNotifications.map((item) => {
    return (
      <Table.Tr key={item.id}>
        <Table.Td>
          {item.type === 'added-cars'
            ? 'Добавлено объявление'
            : item.type === 'not-added-cars'
            ? 'Не добавлено объявление'
            : 'Всем пользователям'}
        </Table.Td>
        <Table.Td>{item.text}</Table.Td>
        <Table.Td>{dayjs(item.createdAt).format('DD.MM.YYYY HH:mm')}</Table.Td>
      </Table.Tr>
    );
  });

  const handleSendPush = () => {
    if (!type || !text) return;
    sendPushNotification({ variables: { data: { type, text } } });
  };

  const pagesCount = Math.ceil(pushNotificationsCount / TAKE);

  return (
    <>
      <Flex direction="row" justify="space-between">
        <Text>Push уведомления</Text>
        <Button onClick={() => setSendPushVisible(true)} size="compact-xs" variant="outline">
          Отправить уведомление
        </Button>
      </Flex>
      <Space h="12px" />
      <TableView>
        <LoadingOverlay visible={loading} overlayBlur={2} />
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Тип</Table.Th>
              <Table.Th>Текст</Table.Th>
              <Table.Th>Дата</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </TableView>
      <Pagination onChange={(v) => setActivePage(v)} value={activePage} size="sm" mt={16} total={pagesCount} />
      <Modal title="Отправить push уведомление" opened={sendPushVisible} onClose={() => setSendPushVisible(false)}>
        <Select
          label="Тип"
          data={[
            { label: 'Добавлено объявление', value: 'added-cars' },
            { label: 'Не добавлено объявление', value: 'not-added-cars' },
            { label: 'Всем пользователям', value: 'all' },
          ]}
          value={type}
          onChange={(v) => setType(v)}
        />
        <Space h="12px" />
        <Textarea label="Текст уведомления" value={text} onChange={(e) => setText(e.target.value)} />
        <Space h="12px" />
        <Group justify="flex-end">
          <Button onClick={() => setSendPushVisible(false)} variant="outline">
            Отмена
          </Button>
          <Button onClick={handleSendPush} loading={sending}>
            Отправить
          </Button>
        </Group>
      </Modal>
    </>
  );
};

const TableView = styled.div`
  overflow-x: scroll;
  position: relative;

  table {
    min-width: 1070px;
    width: 100%;

    th {
      white-space: nowrap;
    }
  }
`;
