import { gql } from '@apollo/client';

export const PUSH_NOTIFICATIONS = gql`
  query PUSH_NOTIFICATIONS($take: Int, $skip: Int) {
    pushNotifications(take: $take, skip: $skip) {
      id
      createdAt
      text
      type
    }
    pushNotificationsCount
  }
`;

export const CREATE_PUSH_NOTIFICATIONS = gql`
  mutation CREATE_PUSH_NOTIFICATIONS($data: SendPushNotificationData!) {
    sendPushNotification(data: $data) {
      id
      createdAt
      text
      type
    }
  }
`;
